<!--
 * @Author: Hou Jiechao
 * @Date: 2022-01-28 10:03:12
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-01-28 10:03:12
 * @FilePath: \工作\storehouse\src\pages\3d\humanbody\index.vue
-->
<template>
  <humanbody />
</template>

<script>
import humanbody from "../../../components/humanbody_3d.vue";

export default {
  name: "hotel_hfrd",
  components: {
    humanbody,
  },
};
</script>