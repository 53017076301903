<!--
 * @Author: Hou Jiechao
 * @Date: 2022-01-28 10:00:42
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-01-28 15:46:18
 * @FilePath: \工作\storehouse\src\components\humanbody_3d.vue
-->
<template>
  <div id="container"></div>
</template>
<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { GUI } from "dat.gui";

import { onMounted } from "vue";
export default {
  setup() {
    onMounted(() => {
      let loader = new GLTFLoader();
      let WIDTH = window.innerWidth;
      let HEIGHT = window.innerHeight;
      let renderer, scene, camera, orbitcontrols, stats;
      let thismodel;
      let modelmaterial;
      let composer;
      let bloomPass;
      let manshow = true; // 控制显示的人物是男或者女
      let showYype = "left2right";
      const personTexture = new THREE.TextureLoader();
      let API = {
        strength: 1.6,
        radius: 0,
        threshold: 0.08,
        speed: 0.02,
        lineColor: 0x1d69d8,
      };
      let container = document.getElementById("container");
      init();
      animate();
      window.addEventListener("resize", onWindowResize, false);

      function init() {
        initStats();
        initRenderer();
        initScene();
        initLights();
        initCamera();
        initRenderPass();
        init3DPerson();
        initControls();
        initHelp();
        initGui();
      }

      function initStats() {
        stats = new Stats();
        document.body.appendChild(stats.dom);
        stats.domElement.style.position = "absolute";
        stats.domElement.style.left = "0px";
        stats.domElement.style.top = "0px";
      }

      function initRenderer() {
        renderer = new THREE.WebGLRenderer({
          antialias: true,
          alpha: true,
          logarithmicDepthBuffer: true,
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(WIDTH, HEIGHT);
        renderer.toneMapping = THREE.ReinhardToneMapping; //这个不知道干嘛用
        // renderer.setClearColor(0x000000, 1)   //设置场景背景色
        container.appendChild(renderer.domElement);
      }

      function initScene() {
        scene = new THREE.Scene();
        const texture = new THREE.TextureLoader();
        const bgtexture = texture.load("/assets/bg5.jpeg"); //加载背景图片
        scene.background = bgtexture;
        let material = new THREE.MeshBasicMaterial({
          color: 0xd81d1d,
        }); //材质对象Material

        // 手掌
        let palm = new THREE.SphereGeometry(5, 5, 5); //创建一个立方体几何对象Geometry
        let palms = new THREE.Mesh(palm, material); //网格模型对象Mesh
        palms.position.x = 40;
        palms.position.z = -5;
        scene.add(palms);
        // 手腕
        let wrist = new THREE.SphereGeometry(5, 5, 5); //创建一个立方体几何对象Geometry
        let wrists = new THREE.Mesh(wrist, material); //网格模型对象Mesh
        wrists.position.x = 36;
        wrists.position.z = -1;
        wrists.position.y = 15;
        scene.add(wrists);

        // 手臂
        let arm = new THREE.SphereGeometry(6, 6, 6); //创建一个立方体几何对象Geometry
        let arms = new THREE.Mesh(arm, material); //网格模型对象Mesh
        arms.position.x = 24;
        arms.position.z = 2;
        arms.position.y = 38;
        scene.add(arms);
      }

      function initCamera() {
        camera = new THREE.PerspectiveCamera(45, WIDTH / HEIGHT, 1, 10000);
        camera.position.set(3.31, 1.65, -270); // 设置相机位置(x, y z) y控制高度,z控制当前的距离
        camera.lookAt(scene.position); // 设置相机方向(指向的场景对象)
      }

      function initControls() {
        orbitcontrols = new OrbitControls(camera, renderer.domElement);
        orbitcontrols.enableDamping = true; //惯性
        orbitcontrols.dampingFactor = 0.1; //惯性系数
        orbitcontrols.enablePan = false; // 禁止左右平移
        orbitcontrols.enableZoom = false; // 禁止缩放
        orbitcontrols.maxPolarAngle = Math.PI / 2; // 上下旋转的最小角度控制
        orbitcontrols.minPolarAngle = Math.PI / 2; // 上下旋转的最大角度控制
        orbitcontrols.addEventListener("change", printPosition);
      }

      function printPosition() {
        console.info("X=" + camera.position.x);
        console.info("Y=" + camera.position.y);
        console.info("Z=" + camera.position.z);
      }

      function initLights() {
        // 添加点光源
        var light = new THREE.PointLight(0xe38210, 1);
        light.position.set(0, 50, 0);
        light.name = "点光源";
        scene.add(light);
        // 环境光
        var ambient = new THREE.AmbientLight(0xffffff, 0.6);
        scene.add(ambient);
      }

      function initRenderPass() {
        // 加载后期发光效果
        var renderScene = new RenderPass(scene, camera);
        //辉光
        bloomPass = new UnrealBloomPass(
          new THREE.Vector2(window.innerWidth, window.innerHeight),
          1.6,
          0.1,
          0.1
        );
        bloomPass.strength = 1.6; // 发光强度
        bloomPass.radius = 0; // 发光半径
        bloomPass.threshold = 0.08; // 发光阈值
        renderer.toneMappingExposure = 1.2; //设置场景曝光度
        composer = new EffectComposer(renderer);
        composer.addPass(renderScene);
        composer.addPass(bloomPass);
      }

      function init3DPerson() {
        const modeltexture = personTexture.load("/assets/left2right.png"); // 加载贴图
        modeltexture.wrapS = THREE.RepeatWrapping;
        modeltexture.wrapT = THREE.RepeatWrapping;
        modeltexture.repeat.set(1, 1);

        modelmaterial = new THREE.MeshLambertMaterial({
          color: 0x1d69d8,
          transparent: true, //允许透明计算
          opacity: 0.9, //半透明设置
          map: modeltexture,
        });

        loader.load(
          "/assets/people.glb",
          function (gltf) {
            thismodel = gltf.scene;
            thismodel.name = "gltfmodel";
            thismodel.scale.set(0.5, 0.5, 0.5);
            thismodel.traverse(function (child) {
              if (child.isMesh && (child.name == "男" || child.name == "女")) {
                child.material = modelmaterial;
                var edges = new THREE.EdgesGeometry(child.geometry, 0);
                var edgesMaterial = new THREE.LineBasicMaterial({
                  color: 0x1d69d8,
                });
                var line = new THREE.LineSegments(edges, edgesMaterial);
                child.add(line);
              }
            });
            scene.add(thismodel);
            animate();
          },
          undefined,
          (err) => {
            console.error(err);
          }
        );
      }

      function initGui() {
        let gui = new GUI();
        gui
          .add(API, "strength", 0, 20)
          .name("发光强度")
          .onChange(updateUvTransform);
        gui
          .add(API, "radius", 0, 20)
          .name("发光半径")
          .onChange(updateUvTransform);
        gui
          .add(API, "threshold", 0, 1)
          .name("发光阈值")
          .onChange(updateUvTransform);

        gui
          .addColor(API, "lineColor")
          .name("描边颜色")
          .onChange(function (value) {
            scene.getObjectByName("男").children[0].material.color =
              new THREE.Color(value);
            scene.getObjectByName("女").children[0].material.color =
              new THREE.Color(value);
            modelmaterial.color = new THREE.Color(value);
          });
        var dropdown = { 性别: "男" };
        var states = ["男", "女"];
        var clipCtrl = gui.add(dropdown, "性别").options(states);
        clipCtrl.onChange(function (value) {
          if (value == "男") {
            manshow = true;
          } else {
            manshow = false;
          }
        });

        var dropdowns = { 扫描方向: "从左到右" };
        var state = ["从下到上", "从左到右"];
        var clipCtrls = gui.add(dropdowns, "扫描方向").options(state);
        clipCtrls.onChange(function (value) {
          if (value == "从左到右") {
            showYype = "left2right";
            const left2right = personTexture.load(
              "/assets/left2right.png"
            ); // 加载贴图
            // left2right.offset.set(0.5, 0.5)
            left2right.wrapS = THREE.RepeatWrapping;
            left2right.wrapT = THREE.RepeatWrapping;
            left2right.repeat.set(1, 1);
            modelmaterial.map = left2right;
          } else {
            showYype = "down2up";
            const down2up = personTexture.load("/assets/down2up.png"); // 加载贴图
            // down2up.offset.set(0.5, 0.5)
            down2up.wrapS = THREE.RepeatWrapping;
            down2up.wrapT = THREE.RepeatWrapping;
            down2up.repeat.set(1, 1);
            modelmaterial.map = down2up;
          }
        });
        gui.add(API, "speed", -1, 1).name("底部圆盘转速").onChange();
      }
      function updateUvTransform() {
        bloomPass.strength = API.strength; // 发光强度
        bloomPass.radius = API.radius; // 发光半径
        bloomPass.threshold = API.threshold; // 发光阈值
      }

      function initHelp() {
        //AxisHelper是一个坐标轴对象，添加到场景中我们就可以看到世界坐标系的具体位置
        // var axes = new THREE.AxesHelper(10000);
        // scene.add(axes);
      }

      function onWindowResize() {
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }
      // function render() {}
      function animate() {
        stats.update();
        if (orbitcontrols) orbitcontrols.update();
        if (showYype == "left2right") {
          modelmaterial.map.offset.x += 0.008;
        } else {
          modelmaterial.map.offset.y += 0.003;
        }
        if (scene.getObjectByName("底部小圆")) {
          scene.getObjectByName("底部小圆").rotation.y += API.speed;
        }

        if (manshow) {
          //true为男子
          if (scene.getObjectByName("男"))
            scene.getObjectByName("男").visible = true;
          if (scene.getObjectByName("女"))
            scene.getObjectByName("女").visible = false;
        } else {
          scene.getObjectByName("女").visible = true;
          scene.getObjectByName("男").visible = false;
        }

        requestAnimationFrame(animate);
        renderer.clear();
        renderer.render(scene, camera);
        composer.render();
      }
    });
  },
};
</script>
<style scoped>
</style>